<template>
  <b-card
    body
  >
    <div class="">
      <b-button
        variant="primary"
        size="sm"
        class="mr-1 rounded-lg p-0"
        @click="$router.go(-1)"
      >
        <feather-icon
          size="2x"
          icon="ChevronLeftIcon"
        />
      </b-button>
      <h4 class="font-bold text-black d-inline-flex mb-0">
        Detail Inbound
      </h4>
    </div>
    <div
      v-if="detail.status !== `Dibatalkan` && detail.status !== `Selesai`"
      class="d-flex flex-row-reverse"
    >
      <div
        class="space-x-3"
      >
        <b-button
          variant="outline-primary"
          class="px-3"
          @click="confirmBatalkan(detail.id)"
        >Batalkan</b-button>
      </div>
    </div>
    <div class="d-flex flex-column border mt-2 mb-2 p-2 text-black space-y-5">
      <div class="w-100 d-flex">
        <div class="w-25 px-0">
          Status Inbound
        </div>
        <div
          class="w-75 px-0"
          :class="statusColor(detail.status)"
        >
          {{ detail.status }}
        </div>
      </div>
      <div class="w-100 d-flex">
        <div class="w-25 px-0">
          Dikirim dari
        </div>
        <div class="w-75 px-0">
          {{ detail.send_from }}
        </div>
      </div>
      <div class="w-100 d-flex">
        <div class="w-25 px-0">
          Dikirim ke
        </div>
        <div class="w-75 px-0">
          {{ detail.send_to }}
        </div>
      </div>
      <div class="w-100 d-flex">
        <div class="w-25 px-0">
          Jasa Pengiriman
        </div>
        <div class="w-75 px-0 text-primary">
          <div class="d-flex items-center gap-3">
            <img
              :src="shippingMethods('img', detail.shipping_type)"
              alt=""
            >
            <div class="">
              {{ shippingMethods('text', detail.shipping_type) }}
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="detail.shipping_type === 'Dikirim Sendiri'"
        class="w-100 d-flex"
      >
        <div class="w-25 px-0">
          Tanggal Pengiriman
        </div>
        <div class="w-75 px-0">
          {{ formatDate(detail.shipping_date) }}
        </div>
      </div>
      <div
        v-if="detail.shipping_type === 'Dikirim Sendiri'"
        class="w-100 d-flex"
      >
        <div class="w-25 px-0">
          Estimasi Sampai Gudang
        </div>
        <div class="w-75 px-0">
          {{ formatDate(detail.estimate_date) }}
        </div>
      </div>
      <div
        v-if="detail.shipping_type !== 'Dikirim Sendiri'"
        class="w-100 d-flex"
      >
        <div class="w-25 px-0">
          Ekspedisi
        </div>
        <div class="w-75 px-0 text-capitalize">
          {{ detail.shipping_type.toLowerCase() }}
        </div>
      </div>
      <div
        v-if="detail.no_resi"
        class="w-100 d-flex"
      >
        <div class="w-25 px-0">
          Nomor Resi
        </div>
        <div class="w-75 px-0">
          {{ detail.no_resi }}
        </div>
      </div>
    </div>
    <div class="d-flex">
      <div
        class="border"
        :style="!Array.isArray(history) ? `width: 55%;` : `width: 100%;`"
      >
        <div class="d-flex align-items-center justify-between m-2">
          <div class="text-black font-bold">
            Data Product
          </div>
          <b-button
            v-if="detail.memo"
            variant="outline-primary"
            size="sm"
            class="-my-[21px]"
            @click="showModalSelisih"
          >
            Lihat Catatan Mitra Gudang
          </b-button>
        </div>
        <div
          v-if="products === null"
          class="h-50 d-flex justify-content-center align-items-center border-t"
        >
          <b-overlay
            variant="light"
            :show="loading"
            spinner-variant="primary"
            blur="0"
            opacity=".5"
            rounded="sm"
          />
        </div>
        <BTable
          v-else
          id="table"
          small
          class="text-left product-table"
          :fields="fields"
          :items="products"
          responsive="sm"
          :busy="loading"
        >
          <template #cell(nama_produk)="data">
            <div class="d-flex gap-2 items-start text-black">
              <b-img
                :src="data.item.image_path ? data.item.image_path : 'https://storage.googleapis.com/komerce/assets/icons/product-placehold.svg'"
                fluid
                class="image-product"
              />
              <div class="">
                <div>{{ data.item.product_name }}</div>
                <div class="text-primary text-[12px]">
                  SKU: {{ data.item.sku }}
                </div>
              </div>
            </div>
          </template>
          <template #cell(variasi)="data">
            <div
              v-if="data.item.is_variant === 0"
              class=""
            >
              {{ '-' }}
            </div>
            <div
              v-else
              class="space-y-3"
            >
              <div
                v-for="i in data.item.variant"
                :key="i.variant_id"
              >
                {{ i.variant_name ? i.variant_name : '-' }}
              </div>
            </div>
          </template>
          <template #cell(dikirim)="data">
            <div
              v-if="data.item.is_variant === 0"
            >{{ data.item.total_inbound }}</div>
            <div
              v-else
              class="space-y-3"
            >
              <div
                v-for="i in data.item.variant"
                :key="i.variant_id"
              >
                {{ i.total_inbound }}
              </div>
            </div>
          </template>
          <template #cell(diterima)="data">
            <div
              v-if="data.item.is_variant === 0"
            >
              {{ data.item.total_inbound_confirm }}
            </div>
            <div
              v-else
              class="space-y-3"
            >
              <div
                v-for="i in data.item.variant"
                :key="i.variant_id"
              >
                {{ i.total_inbound_confirm }}
              </div>
            </div>
          </template>
        </BTable>
      </div>
      <div
        v-if="!Array.isArray(history)"
        class="border ml-2"
        style="width: 45%;"
      >
        <div class="text-black font-bold m-2">
          Riwayat Perjalanan :</div>
        <div class="d-flex flex-column p-1 border-t text-black product-table">
          <div
            v-if="history === null"
            class="h-50 d-flex justify-content-center align-items-center"
          >
            <b-overlay
              variant="light"
              :show="loading"
              spinner-variant="primary"
              blur="0"
              opacity=".5"
              rounded="sm"
            />
          </div>
          <div v-else>
            <div
              v-if="history.status.code === 200"
              class="space-y-7"
            >
              <div
                v-for="(item, index) in history.manifest"
                :key="index"
                class="d-flex"
              >
                <div class="d-flex flex-column w-25 justify-content-center">
                  <span>{{ formatDateManifest(item.manifest_date) }}</span>
                  <span>{{ formatTimeManifest(item.manifest_time) }} WIB</span>
                </div>
                <div class="w-25 d-flex align-items-center justify-content-center">
                  <div class="relative">
                    <img
                      :src="ellipseColor(item.manifest_code)"
                      alt=""
                    >
                    <div
                      v-if="item.manifest_code !== `1`"
                      class="dash border"
                    />
                  </div>
                </div>
                <div class="w-75 d-flex flex-column justify-content-center">
                  <span :class="item.manifest_code === `5` ? `text-primary` : ``">{{ item.city_name }}</span>
                  <span :class="item.manifest_code === `5` ? `text-primary` : ``">{{ item.manifest_description }}</span>
                </div>
              </div>
            </div>
            <div
              v-else
              class="d-flex justify-content-center flex-column space-y-3"
            >
              <img src="https://storage.googleapis.com/komerce/assets/tidak-dapat-tracking.svg">
              <div class="text-black font-black text-xl text-center mt-3">
                Ekspedisi yang kamu gunakan belum
                dapat ditracking melalui Kompack
              </div>
              <div class="text-center mx-3">
                kamu dapat melakukan pengecekan riwayat perjalanan melalui website ekspedisi
                yang kamu gunakan.</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      id="modal-selisih"
      size="lg"
      scrollable
      centered
      hide-header
      hide-header-close
      no-close-on-backdrop
      no-close-on-esc
      ok-only
      ok-variant="outline-primary"
      ok-title="Kembali"
    >
      <template>
        <div class="text-black text-[20px] font-[600] mb-[24px]">
          Perbedaan Data
        </div>
        <b-table
          :fields="fieldSelisih"
          :items="productSelisih"
          sticky-header="200px"
        >
          <template #cell(product_name)="row">
            <div class="text-black">
              <div class="">
                {{ row.item.product_name }}
                <div class="text-primary text-[12px]">
                  SKU: {{ row.item.sku }}
                </div>
              </div>
            </div>
          </template>
          <template #cell(variant)="row">
            <div
              class="space-y-3"
              style="min-width: 120px;"
            >
              <div
                v-if="row.item.is_variant === 0"
                class=""
              >
                -
              </div>
              <div
                v-for="v in row.item.variant"
                v-else
                :key="v.variant_id"
              >
                {{ v.variant_name ? v.variant_name : '-' }}
              </div>
            </div>
          </template>
          <template #cell(total_inbound)="row">
            <div
              v-if="row.item.is_variant === 0"
              class=""
            >
              {{ row.item.total_inbound }}
            </div>
            <div
              v-for="v in row.item.variant"
              v-else
              :key="v.variant_id"
            >
              {{ v.total_inbound }}
            </div>
          </template>
          <template #head(total_inbound_confirm)>
            <div class="">
              Jumlah Diterima
            </div>
          </template>
          <template #cell(total_inbound_confirm)="row">
            <div
              v-if="row.item.is_variant === 0"
              class=""
            >
              {{ row.item.total_inbound_confirm }}
            </div>
            <div
              v-for="v in row.item.variant"
              v-else
              :key="v.variant_id"
            >
              {{ v.total_inbound_confirm }}
            </div>
          </template>
          <template #cell(selisih)="row">
            <div
              class="space-y-3"
              style="min-width: 80px;"
            >
              <div
                v-if="row.item.is_variant === 0"
              >
                {{ row.item.selisih }}
              </div>
              <div
                v-for="v in row.item.variant"
                v-else
                :key="v.variant_id"
              >
                {{ v.selisih }}
              </div>
            </div>
          </template>
        </b-table>
        <div class="text-[#222222] mt-[12px]">
          Catatan Mitra Gudang
        </div>
        <div
          class="mt-[12px] textarea-memo"
        >
          <!-- eslint-disable-next-line vue/no-v-html -->
          <div v-html="handleMemo(detail.memo)" />
        </div>
      </template>
    </b-modal>
  </b-card>
</template>

<script>
import { mapState } from 'vuex'
import moment from 'moment'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'RiwayatInbound',
  data() {
    return {
      loading: true,
      isTambah: false,
      detail: JSON.parse(localStorage.getItem('detailInbound')),
      fields: [
        {
          key: 'nama_produk',
          label: 'Nama Produk',
          thClass: 'text-black text-capitalize py-1',
          tdClass: 'text-black align-top py-1',
        },
        {
          key: 'variasi',
          label: 'Variasi',
          thClass: 'text-black text-capitalize py-1',
          tdClass: 'text-black align-top py-1',
        },
        {
          key: 'dikirim',
          label: 'Dikirim',
          thClass: 'text-black text-capitalize py-1 text-center',
          tdClass: 'text-black text-center align-top py-1',
        },
        {
          key: 'diterima',
          label: 'Diterima Gudang',
          thClass: 'text-black text-capitalize py-1 text-center',
          tdClass: 'text-black text-center align-top py-1',
        },
      ],
      fieldSelisih: [
        {
          key: 'product_name', label: 'Nama Produk', thClass: 'text-left text-capitalize !text-black', tdClass: 'text-black text-left align-top',
        },
        {
          key: 'variant', label: 'Variasi', thClass: 'text-left text-capitalize !text-black', tdClass: 'text-black text-left align-top',
        },
        {
          key: 'total_inbound', label: 'Dikirim', thClass: 'text-capitalize !text-black', tdClass: 'text-black space-y-3 align-top',
        },
        {
          key: 'total_inbound_confirm', label: 'Diterima Gudang', thClass: 'text-capitalize !text-black', tdClass: 'text-black space-y-3 align-top',
        },
        {
          key: 'selisih', label: 'Selisih', thClass: 'text-capitalize !text-black', tdClass: 'text-black space-y-3 align-top',
        },
      ],
      products: null,
      productSelisih: [],
      history: null,
      body: [],
      variant: [],
    }
  },
  computed: {
    ...mapState('riwayatPengajuan', ['detailInbound']),
  },
  created() {
    this.fetchDetailInbound()
  },
  methods: {
    fetchDetailInbound() {
      this.loading = true
      this.$store
        .dispatch('riwayatPengajuan/getDetailInbound', {
          inbound_id: this.$route.params.id,
        })
        .then(() => {
          this.products = this.detailInbound.products
          this.history = this.detailInbound.history_shipping
          this.loading = false
        })
        .catch(() => {
          this.loading = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Gagal',
              icon: 'AlertCircleIcon',
              text: 'Gagal load data, silahkan coba lagi',
              variant: 'danger',
            },
          }, 2000)
        })
    },
    shippingMethods(part, shipping) {
      if (part === 'img') {
        if (shipping !== 'Dikirim Sendiri') {
          return 'https://storage.googleapis.com/komerce/assets/menggunakan-ekspedisi-orange.svg'
        }
        return 'https://storage.googleapis.com/komerce/assets/dikirim-sendiri-orange.svg'
      }
      if (shipping !== 'Dikirim Sendiri') {
        return 'Menggunakan Ekspedisi'
      }
      return 'Dikirim Sendiri'
    },
    statusColor(status) {
      if (status === 'Proses') {
        return 'text-warning'
      }
      if (status === 'Selesai') {
        return 'text-success'
      }
      return 'text-danger'
    },
    formatDate(value) {
      return moment(value).format('DD MMMM YYYY')
    },
    formatDateManifest(value) {
      return moment(value).format('DD MMM')
    },
    formatTimeManifest(value) {
      return value.replace(/:[^:]*$/, '')
    },
    ellipseColor(value) {
      if (value === '5') {
        return 'https://storage.googleapis.com/komerce/assets/tracking-ellipse-orange.svg'
      }
      return 'https://storage.googleapis.com/komerce/assets/tracking-elipse.svg'
    },
    confirmBatalkan(data) {
      this.$swal({
        text: 'Anda yakin melakukan pembatalan inbound?',
        title: 'Batalkan Inbound',
        icon: 'warning',
        iconHtml: '<img src="https://storage.googleapis.com/komerce/core/icon-popup-warning.png">',
        showCancelButton: true,
        cancelButtonText: 'Tidak',
        confirmButtonText: 'Ya',
        customClass: {
          icon: 'border-0 w-50 my-5',
          confirmButton: 'btn btn-primary mr-1 px-5',
          cancelButton: 'btn btn-outline-primary px-4',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.batalkanInbound(data)
        }
      })
    },
    batalkanInbound(data) {
      this.$http_komship.put(`/v1/komship/inbound/cancel/${data}`)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              icon: 'CheckIcon',
              text: 'Success batalkan inbound',
              variant: 'success',
            },
          }, 2000)
          this.$router.go(-1)
        }).catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Gagal',
              icon: 'AlertCircleIcon',
              text: 'Gagal batalkan inbound, silahkan coba lagi!',
              variant: 'danger',
            },
          }, 2000)
          this.$router.refresh()
        })
    },
    convertToPayLoad() {
      for (let i = 0; i < this.detailInbound.products.length; i += 1) {
        if (this.detailInbound.products[i].variant === []) {
          this.variant = []
        } else {
          this.variant = []
          for (let j = 0; j < this.detailInbound.products[i].variant.length; j += 1) {
            this.variant.push({
              option_id: this.detailInbound.products[i].variant[j].variant_id,
              // eslint-disable-next-line radix
              total_inbound: parseInt(this.detailInbound.products[i].variant[j].total_inbound),
              is_update: 1,
            })
          }
        }
        this.body.push({
          product_id: this.detailInbound.products[i].id,
          is_variant: this.detailInbound.products[i].is_variant,
          // eslint-disable-next-line radix
          total_inbound: parseInt(this.detailInbound.products[i].total_inbound),
          is_update: 1,
          variant: this.variant,
        })
      }
    },
    handleSave() {
      this.convertToPayLoad()
      this.$http_komship.put('/v1/komship/inbound/update', {
        inbound_id: this.$route.params.id,
        product: this.body,
      })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              icon: 'CheckIcon',
              text: 'Success Edit inbound',
              variant: 'success',
            },
          }, 2000)
          this.fetchDetailInbound()
        }).catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Gagal',
              icon: 'AlertCircleIcon',
              text: 'Gagal Edit inbound, silahkan coba lagi!',
              variant: 'danger',
            },
          }, 2000)
          this.$router.refresh()
        })
    },
    handleBack() {
      this.isTambah = !this.isTambah
    },
    showModalSelisih() {
      const filteredData = this.products.filter(item => {
        if (item.is_variant === 1) {
          return item.variant.some(v => v.total_inbound !== v.total_inbound_confirm)
        }
        return item.total_inbound !== item.total_inbound_confirm
      })
      const transformedData = filteredData.map(item => {
        if (item.is_variant === 1) {
          const newVariant = item.variant.filter(v => v.total_inbound !== v.total_inbound_confirm)
            .map(v => ({ ...v, selisih: v.total_inbound_confirm > v.total_inbound ? `Lebih ${v.total_inbound_confirm - v.total_inbound}` : `Kurang ${v.total_inbound - v.total_inbound_confirm}` }))
          return { ...item, variant: newVariant }
        }
        return { ...item, selisih: item.total_inbound_confirm > item.total_inbound ? `Lebih ${item.total_inbound_confirm - item.total_inbound}` : `Kurang ${item.total_inbound - item.total_inbound_confirm}` }
      })

      this.productSelisih = transformedData
      this.$bvModal.show('modal-selisih')
    },
    handleMemo(memo) {
      const urlRegex = /(https?:\/\/[^\s]+)/g

      const memoWithLinks = memo ? memo.replace(urlRegex, '<a href="$1" target="_blank">$1</a>') : ''

      return memoWithLinks
    },
  },
}
</script>

<style scoped>
.image-product {
  object-fit: cover;
  object-position: center center;
  width: 50px!important;
  height: 50px!important;
}

.dash{
  position: absolute;
  height: 45px;
  margin: 4px 0px;
  margin-left: 5px;
}

.product-table{
  height: 400px;
  overflow-y: auto;
}
.textarea-memo {
  border: 1px solid #e2e2e2;
  padding: 12px 16px;
  font-size: 14px;
  min-height: 100px;
  border-radius: 8px;
}
</style>
